<template>
    <div>
        <b-row v-if="subscriptions.subscription">
            <b-col>
                <b-card class="mt-1 text-center h-100 ">
                    <div class="mt-2 h4 text-white" style="color: #7367f0 !important; font-weight: bold;">
                        <feather-icon icon="ShoppingBagIcon" size="19" />
                        Información de subscripción
                    </div>
                    <div class="d-flex justify-content-center mt--5 mb-1">
                        <b-avatar size="lg"> </b-avatar>
                    </div>
                    <div class="mt-2 h4 text-white" style="color: #7367f0 !important; font-weight: bold;">
                        {{
                            subscriptions.subscription.plan.product_name
                        }}
                    </div>
                    <div>
                        <b-badge
                            v-if="
                                subscriptions.subscription
                                    .stripe_status == 'active'
                            "
                            variant="success"
                            >Activo</b-badge
                        >
                        <b-badge v-else variant="danger"
                            >Inactivo</b-badge
                        >
                    </div>
                    <div class="mt-1">
                        <b-badge
                            variant="warning"
                        >
                            Vigencia:
                            {{
                                formatDate(
                                    subscriptions.subscription
                                        .valid_from
                                )
                            }} 
                            - 
                            {{
                                formatDate(
                                    subscriptions.subscription
                                        .valid_until
                                )
                            }}
                        </b-badge>
                    </div>
                    <b-alert
                        v-if="
                            subscriptions.subscription.stripe_status !=
                            'active'
                        "
                        show
                        variant="danger"
                        class="p-2"
                        >Alerta: Revise en el portal de facturación sus
                        metodos de pago. No podra realizar ningun
                        movimiento mientras su subscripción permanezca
                        inactiva.
                    </b-alert>
                    <b-alert
                        v-if="
                            subscriptions.subscription
                                .cancel_at_period_end
                        "
                        show
                        variant="warning"
                        class="p-2"
                        >Atención: Su subscripción esta marcada como
                        cancelada y terminara el
                        {{
                            formatDate(
                                subscriptions.subscription.cancel_at
                            )
                        }}
                    </b-alert>

                    <hr>
                        <ul v-if="subscriptions.subscription.plan.description" class="mt-2" style="text-align: left;">
                            <li v-for="(item, index) in getDescriptionArray()" :key="index">
                                {{ capitalize(item) }}
                            </li>
                        </ul>
                    <hr>       
                    <div class="mt-2" style="color: #7367f0 !important; font-weight: bold; font-size: 16px;">                        
                        ${{ subscriptions.subscription.plan.price }} MXN {{ subscriptions.subscription.plan.interval }}
                    </div>

                    <div class="mt-2">
                        <b-button
                            variant="primary"
                            class="mb-2 btn-sm-block"
                            @click="
                                getUrlPortalSession(
                                    subscriptions.subscription
                                        .stripe_subscription_id
                                )
                            "
                        >
                            Portal de facturación
                        </b-button>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col v-for="item in subscriptions.plans" :key="item.id" class="gutter-md mb-2">
                <b-card class="mt-1 text-center h-100 ">
                    <div class="d-flex justify-content-center mt--5 mb-1">
                        <b-avatar size="lg"> </b-avatar>
                    </div>
                    <div class="mt-2 h4 text-white" style="color: #7367f0 !important; font-weight: bold;">
                        {{ item.product_name }}
                    </div>
                    <hr>
                        <ul v-if="item.description" class="mt-2" style="text-align: left;">
                            <li v-for="(item, index) in item.description" :key="index">
                                {{ item }}
                            </li>
                        </ul>
                    <hr>           
                    <div class="mt-2" style="color: #7367f0 !important; font-weight: bold; font-size: 16px;">                        
                        ${{ item.price }} MXN {{ item.interval }}
                    </div>
                    <div
                        class="d-flex align-items-center justify-content-center mt-3 mb-0 pb-0"
                    >
                        <b-button
                            variant="primary"
                            @click="getUrlCheckoutSession(item.stripe_plan_id)"
                            >Contratar
                            </b-button
                        >
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@axios";
import { formatDate } from "@core/utils/filter";

export default {
    name: "Subscriptions",
    components: {},
    data() {
        return {
            userData: JSON.parse(localStorage.getItem("userData")),
            formatDate,
        };
    },
    beforeMount() {
        this.fetchSubscription(this.$route.params.id)
            .then((res) => {   
                if (!res || (res.stripe_status !== 'active' && res.stripe_status !== 'trialing')) {
                    this.$swal({
                        title: "Atención",
                        text: "No cuentas con una suscripción activa. Ten en cuenta que no podrás registrar ventas en el sistema. Si deseas contratar un plan, dirígete a la sección de planes y selecciona el que mejor se ajuste a las necesidades de tu negocio",
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    }
            })
        this.fetchPlans();
    },    
    methods: {
        ...mapActions("subscriptions", ["fetchPlans", "fetchSubscription"]),

        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        async getUrlCheckoutSession(plan_id) {
            const url = `${process.env.VUE_APP_API_URL}/v1/stripe/checkout_session/${this.$route.params.id}/${plan_id}`;
            const result = await axios.get(url);
            window.location.href = result.data.session_url;
            return;
        },
        async getUrlPortalSession(subscription_id) {
            const url = `${process.env.VUE_APP_API_URL}/v1/stripe/billing_portal/${this.$route.params.id}/${subscription_id}`;
            const result = await axios.get(url);
            window.location.href = result.data.portal_session_url;
            return;
        },
        getDescriptionArray() {
            if (typeof this.subscriptions.subscription.plan.description === 'string') {
            return this.subscriptions.subscription.plan.description.split(', ');
            } else {
            return [];
        }
        
  }
    },
    computed: {
        ...mapState(["subscriptions"]),
    },
};
</script>

<style lang="scss" scoped>
.gutter-md {
    gap: 1rem;
}
</style>
