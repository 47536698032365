var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.subscriptions.subscription)?_c('b-row',[_c('b-col',[_c('b-card',{staticClass:"mt-1 text-center h-100 "},[_c('div',{staticClass:"mt-2 h4 text-white",staticStyle:{"color":"#7367f0 !important","font-weight":"bold"}},[_c('feather-icon',{attrs:{"icon":"ShoppingBagIcon","size":"19"}}),_vm._v(" Información de subscripción ")],1),_c('div',{staticClass:"d-flex justify-content-center mt--5 mb-1"},[_c('b-avatar',{attrs:{"size":"lg"}})],1),_c('div',{staticClass:"mt-2 h4 text-white",staticStyle:{"color":"#7367f0 !important","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.subscriptions.subscription.plan.product_name)+" ")]),_c('div',[(
                            _vm.subscriptions.subscription
                                .stripe_status == 'active'
                        )?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Activo")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Inactivo")])],1),_c('div',{staticClass:"mt-1"},[_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v(" Vigencia: "+_vm._s(_vm.formatDate( _vm.subscriptions.subscription .valid_from ))+" - "+_vm._s(_vm.formatDate( _vm.subscriptions.subscription .valid_until ))+" ")])],1),(
                        _vm.subscriptions.subscription.stripe_status !=
                        'active'
                    )?_c('b-alert',{staticClass:"p-2",attrs:{"show":"","variant":"danger"}},[_vm._v("Alerta: Revise en el portal de facturación sus metodos de pago. No podra realizar ningun movimiento mientras su subscripción permanezca inactiva. ")]):_vm._e(),(
                        _vm.subscriptions.subscription
                            .cancel_at_period_end
                    )?_c('b-alert',{staticClass:"p-2",attrs:{"show":"","variant":"warning"}},[_vm._v("Atención: Su subscripción esta marcada como cancelada y terminara el "+_vm._s(_vm.formatDate( _vm.subscriptions.subscription.cancel_at ))+" ")]):_vm._e(),_c('hr'),(_vm.subscriptions.subscription.plan.description)?_c('ul',{staticClass:"mt-2",staticStyle:{"text-align":"left"}},_vm._l((_vm.getDescriptionArray()),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(_vm.capitalize(item))+" ")])}),0):_vm._e(),_c('hr'),_c('div',{staticClass:"mt-2",staticStyle:{"color":"#7367f0 !important","font-weight":"bold","font-size":"16px"}},[_vm._v(" $"+_vm._s(_vm.subscriptions.subscription.plan.price)+" MXN "+_vm._s(_vm.subscriptions.subscription.plan.interval)+" ")]),_c('div',{staticClass:"mt-2"},[_c('b-button',{staticClass:"mb-2 btn-sm-block",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getUrlPortalSession(
                                _vm.subscriptions.subscription
                                    .stripe_subscription_id
                            )}}},[_vm._v(" Portal de facturación ")])],1)],1)],1)],1):_c('b-row',_vm._l((_vm.subscriptions.plans),function(item){return _c('b-col',{key:item.id,staticClass:"gutter-md mb-2"},[_c('b-card',{staticClass:"mt-1 text-center h-100 "},[_c('div',{staticClass:"d-flex justify-content-center mt--5 mb-1"},[_c('b-avatar',{attrs:{"size":"lg"}})],1),_c('div',{staticClass:"mt-2 h4 text-white",staticStyle:{"color":"#7367f0 !important","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.product_name)+" ")]),_c('hr'),(item.description)?_c('ul',{staticClass:"mt-2",staticStyle:{"text-align":"left"}},_vm._l((item.description),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),_c('hr'),_c('div',{staticClass:"mt-2",staticStyle:{"color":"#7367f0 !important","font-weight":"bold","font-size":"16px"}},[_vm._v(" $"+_vm._s(item.price)+" MXN "+_vm._s(item.interval)+" ")]),_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-3 mb-0 pb-0"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getUrlCheckoutSession(item.stripe_plan_id)}}},[_vm._v("Contratar ")])],1)])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }